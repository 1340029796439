import { Action, createReducer, on } from '@ngrx/store';

import * as DataAccessFairsActions from './data-access-fairs.actions';

import { FairsConfiguration } from '../interfaces/environment.interfaces';

export const DATA_ACCESS_FAIRS_FEATURE_KEY = 'dataAccessFairs';

export interface FairsState {
  config?: FairsConfiguration;
}

export interface DataAccessFairsPartialState {
  readonly [DATA_ACCESS_FAIRS_FEATURE_KEY]: FairsState;
}

export const initialFairsState: FairsState = {};

const dataAccessFairsReducer = createReducer(
  initialFairsState,
  on(DataAccessFairsActions.appOpenedSucceeded, (state, { config }) => ({
    ...state,
    config,
  }))
);

export function fairsReducer(state: FairsState | undefined, action: Action) {
  return dataAccessFairsReducer(state, action);
}
