import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { FAIRS_CONFIGURATION, FairsConfiguration } from '../interfaces/environment.interfaces';

@Injectable()
export class DataAccessFairsService {
  constructor(@Inject(FAIRS_CONFIGURATION) private fairsConfiguration: FairsConfiguration) {}

  getFairsConfig(): Observable<FairsConfiguration> {
    return of(this.fairsConfiguration);
  }
}
