import { Injectable } from '@angular/core';

import { ExtendedWindow } from '../interface/window.interface';

/**
 * Provides a reference to the native window object and makes it mockable for tests
 * Naming and structure inspired by native angular methods (elementRef.nativeElement etc.)
 */
@Injectable({
  providedIn: 'root',
})
export class WindowRef {
  private readonly window: ExtendedWindow;

  /**
   * Returns the native window object if it exists
   *
   * @returns
   */
  get nativeWindow(): ExtendedWindow {
    return this.window;
  }

  constructor() {
    this.window = <ExtendedWindow>window;
  }
}
