import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { map } from 'rxjs';

import * as DataAccessFairsActions from './data-access-fairs.actions';
import * as DataAccessFairsSelectors from './data-access-fairs.selectors';

@Injectable()
export class DataAccessFairsFacade {
  insightsFairConfig$ = this.store.pipe(
    select(DataAccessFairsSelectors.getFairsConfig),
    map((fairs) => {
      if (fairs) {
        return fairs['229'];
      } else {
        return undefined;
      }
    })
  );
  upcomingFairs$ = this.store.pipe(
    select(DataAccessFairsSelectors.getFairsConfig),
    map((fairs) => {
      if (fairs) {
        return Object.values(fairs).filter((fair) => {
          if (fair.startDate) {
            return fair.startDate > new Date();
          } else {
            return undefined;
          }
        });
      }
      return undefined;
    })
  );
  pastFairs$ = this.store.pipe(
    select(DataAccessFairsSelectors.getFairsConfig),
    map((fairs) => {
      if (fairs) {
        return Object.values(fairs).filter((fair) => {
          if (fair.startDate) {
            return fair.startDate < new Date();
          } else {
            return undefined;
          }
        });
      }
      return undefined;
    })
  );

  appOpened() {
    this.store.dispatch(DataAccessFairsActions.appOpened());
  }

  constructor(private readonly store: Store) {}
}
