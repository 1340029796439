import { InjectionToken } from '@angular/core';

export interface FairsConfiguration {
  [key: string]: FairConfiguration;
}

export interface FairConfiguration {
  name: string;
  id: string;
  startDate?: Date;
  endDate?: Date;
  venue?: string;
  description?: string;
  directusUrl?: string;
}

export const FAIRS_CONFIGURATION = new InjectionToken<FairsConfiguration>('FAIRS_CONFIGURATION');
