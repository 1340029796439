import { Action, createReducer, on } from '@ngrx/store';

import * as DataAccessScreensizeActions from './data-access-screensize.actions';

export const DATA_ACCESS_SCREENSIZE_FEATURE_KEY = 'dataAccessScreensize';

export interface SliderConfig {
  slidesPerView: number;
  scrollbar:
    | {
        draggable: boolean;
      }
    | boolean;
}

export interface State {
  size?: string;
}

export interface DataAccessScreensizePartialState {
  readonly [DATA_ACCESS_SCREENSIZE_FEATURE_KEY]: State;
}

export const initialState: State = {
  size: undefined,
};

const dataAccessScreensizeReducer = createReducer(
  initialState,
  on(DataAccessScreensizeActions.resized, (state, { size }) => ({
    ...state,
    size: size,
  }))
);

export function screensizeReducer(state: State | undefined, action: Action) {
  return dataAccessScreensizeReducer(state, action);
}
