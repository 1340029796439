import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as DataAccessScreensizeActions from './data-access-screensize.actions';
import { DisplaySize } from './data-access-screensize.models';
import * as DataAccessScreensizeSelectors from './data-access-screensize.selectors';

@Injectable()
export class DataAccessScreensizeFacade {
  size$ = this.store.pipe(select(DataAccessScreensizeSelectors.getSize));

  constructor(private readonly store: Store) {}

  screenResized(size: number) {
    let displaySize: string;

    if (size <= DisplaySize.phone) {
      displaySize = 'phone';
    } else if (size <= DisplaySize.tablet) {
      displaySize = 'tablet';
    } else if (size <= DisplaySize.laptop) {
      displaySize = 'laptop';
    } else if (size >= DisplaySize.desktop) {
      displaySize = 'desktop';
    }

    this.size$
      .subscribe((currentDisplaySize) => {
        if (displaySize && currentDisplaySize !== displaySize) {
          this.store.dispatch(DataAccessScreensizeActions.resized({ size: displaySize }));
        }
      })
      .unsubscribe();
  }
}
