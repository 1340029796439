import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, of, switchMap } from 'rxjs';

import * as DataAccessFairsActions from './data-access-fairs.actions';

import { DataAccessFairsService } from '../services/data-access-fairs.service';

@Injectable()
export class DataAccessFairsEffects {
  loadFairConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataAccessFairsActions.appOpened),
      switchMap((_action) => {
        return this.fairService.getFairsConfig().pipe(
          map((config) => {
            return DataAccessFairsActions.appOpenedSucceeded({
              config: config,
            });
          }),
          catchError((err) => of(DataAccessFairsActions.appOpenedFailed({ error: err.status.toString() })))
        );
      })
    )
  );

  constructor(private readonly actions$: Actions, private fairService: DataAccessFairsService) {}
}
